<div>
  <div>
    <div [formGroup]="mchoiceForm">
      <div style="text-align: center;">{{slide.content.description}}</div>
      <h2 style="text-align: center;padding: 30px">{{slide.content.question}}</h2>
      <div style="text-align: center;" class="mb-3">
        <img class="img-sec" *ngIf="this.slide.content.image && this.slide.content.image !== ''" src="{{this.slide.content.image}}"
          >
          <p *ngIf="this.slide.content.extraConfig.multyChoice">
          You may choose multiple options.
          </p>
      </div>
      <div style="margin: 0 auto;width: fit-content;"  formArrayName="textField">
        <div *ngFor="let fld of textFieldFormArray.controls; let i = index">

          <div class="custom-control custom-checkbox mb-2">
            <input type="checkbox" class="custom-control-input" id="customCheck{{i}}" name="{{i}}" [formControlName]="i" (change)="textChanged($event, i)">
            <label class="custom-control-label" style="margin-left: 30px;" for="customCheck{{i}}">{{slide.content.options[i]}}</label>
          </div>

          <!-- <input style="margin-right: 20px;" type="checkbox" name="{{i}}" [formControlName]="i" (change)="textChanged($event, i)"> <label style="font-size: 20px;">{{slide.content.options[i]}}
          </label> -->
        </div>
        <!-- <button mat-button mat-raised-button color="primary">Update</button> -->

      </div>
    </div>
  </div>
</div>
