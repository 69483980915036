import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-present-ui-multiplechoice',
  templateUrl: './ui-multiplechoice.component.html',
  styleUrls: ['./ui-multiplechoice.component.scss']
})
export class UiMultiplechoiceComponent implements OnInit {

  public slide: any;

  @Input()
  set slidedata(val: any) {
    this.slide = val;
    this.slide.content.options.forEach(element => {
      this.textFieldFormArray.push(new FormControl(false))
    });
  }

  public mchoiceForm = new FormGroup({
    textField: new FormArray([]),
  });

  constructor() {

  }

  ngOnInit(): void {
  }

  get textFieldFormArray() {
    return this.mchoiceForm.controls.textField as FormArray;
  }
  public textChanged(evt, index) {
    if (this.slide.content.extraConfig.multyChoice) {
      var i = 0;
      this.slide.content.options.forEach(element => {
        if (i !== index) {
          this.textFieldFormArray.at(i).setValue(false);
        }
        i++;
      });
    }
    this.slide.content.answers = this.mchoiceForm.value;
  }

}
