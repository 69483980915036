import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from '../../common.service';

@Injectable({
    providedIn: 'root'
})
export class PresentationService {
    apiURL: string = '';
    constructor(private httpClient: HttpClient, public commonService: CommonService) {
        this.apiURL = this.commonService.getSiteUrl();
    }
    public addPresentation(presentation) {
        let headers = new HttpHeaders().set('authtoken', localStorage.getItem('authtoken'));
        return this.httpClient.post(`${this.apiURL}/presentation`, presentation, { headers: headers });
    }
    public updatePresentation(presentation) {
        let headers = new HttpHeaders().set('authtoken', localStorage.getItem('authtoken'));
        return this.httpClient.post(`${this.apiURL}/presentation/update`, presentation, { headers: headers });
    }
    public getPresentation(id) {
        return this.httpClient.get(`${this.apiURL}/presentation?slideid=` + id);
    }
    public delPresentation(id) {
        let headers = new HttpHeaders().set('authtoken', localStorage.getItem('authtoken'));
        return this.httpClient.delete(`${this.apiURL}/presentation?slideid=` + id, { headers: headers });
    }
    public delFolder(id) {
        let headers = new HttpHeaders().set('authtoken', localStorage.getItem('authtoken'));
        return this.httpClient.delete(`${this.apiURL}/folder?folderId=` + id, { headers: headers });
    }
    public renamePresentation(data) {
        let headers = new HttpHeaders().set('authtoken', localStorage.getItem('authtoken'));
        return this.httpClient.post(`${this.apiURL}/presentation/rename`, data, { headers: headers });
    }
    public renameFolder(data) {
        let headers = new HttpHeaders().set('authtoken', localStorage.getItem('authtoken'));
        return this.httpClient.post(`${this.apiURL}/folder/rename`, data, { headers: headers });
    }
    public moveToFolder(data) {
        let headers = new HttpHeaders().set('authtoken', localStorage.getItem('authtoken'));
        return this.httpClient.post(`${this.apiURL}/presentation/movetofolder`, data, { headers: headers });
    }
    public getPresentationbyKey(id) {
        return this.httpClient.get(`${this.apiURL}/presentation?id=` + id);
    }
    public getAllPresentation() {
        let headers = new HttpHeaders().set('authtoken', localStorage.getItem('authtoken'));
        return this.httpClient.get(`${this.apiURL}/presentation/getall`, { headers: headers });

    }
    public getFolderPresentation(id) {
        let headers = new HttpHeaders().set('authtoken', localStorage.getItem('authtoken'));
        return this.httpClient.get(`${this.apiURL}/presentation/getall?folderid=` + id, { headers: headers });
    }
    public saveSurvey(dt) {
        return this.httpClient.post(`${this.apiURL}/presentation/survey`, dt);
    }

    public addFolder(folder) {
        let headers = new HttpHeaders().set('authtoken', localStorage.getItem('authtoken'));
        return this.httpClient.post(`${this.apiURL}/folder`, folder, { headers: headers });
    }
    public getAllFolders() {
        let headers = new HttpHeaders().set('authtoken', localStorage.getItem('authtoken'));
        return this.httpClient.get(`${this.apiURL}/folder/getall`, { headers: headers });
    }
    public searchPresentation(search, folderId) {
        let headers = new HttpHeaders().set('authtoken', localStorage.getItem('authtoken'));
        return this.httpClient.get(`${this.apiURL}/presentation/search?key=` + search + `&folderid=` + folderId, { headers: headers });
    }
    public searchFolders(search) {
        let headers = new HttpHeaders().set('authtoken', localStorage.getItem('authtoken'));
        return this.httpClient.get(`${this.apiURL}/folder/search?key=` + search, { headers: headers });
    }
}
