<div>
  <div>
    <div [formGroup]="mchoiceForm">
      <div style="text-align: center;">{{slide.content.description}}</div>
      <h2 style="text-align: center;padding: 30px">{{slide.content.question}}</h2>
      <div style="text-align: center;">
        <img *ngIf="this.slide.content.image && this.slide.content.image !== ''" src="{{this.slide.content.image}}"
          class="img-sec">
      </div>
      <div class="content-sec field-set">
        <textarea *ngIf="this.slide.content.options"
          placeholder="Short answers are recommended. You have 250 characters left." maxlength="250"
          rows="4" type="text" formControlName="textFieldOne"
          name="textFieldOne"></textarea>
        <textarea *ngIf="!this.slide.content.options"
          placeholder="Short answers are recommended. You have 250 characters left." (change)="textChanged($event)"
          maxlength="250" rows="4" type="text" formControlName="textFieldOne"
          name="textFieldOne"></textarea>

        <p *ngIf="this.slide.content.options" style="width: 100%;">You can submit multiple answers</p>
  
        <div *ngIf="this.slide.content.options">
          <p *ngIf="this.mchoiceForm.get('textField').value.length">
            Thanks, we received your input! Add another above, if you want.
            <!-- <span *ngFor="let txt of this.mchoiceForm.get('textField').value; let i = index">
              {{txt}} <span *ngIf="this.mchoiceForm.get('textField').value.length !== (i+1)">, </span>
            </span> -->
          </p>
          <button (click)="addMore()" class="btn btn-gray" style="float: right; margin-bottom: 40px; width: 100%;">Add</button>
        </div>
      </div>
    </div>
  </div>
</div>
