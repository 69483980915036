import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-present-ui-pinonimage',
  templateUrl: './ui-pinonimage.component.html',
  styleUrls: ['./ui-pinonimage.component.scss']
})
export class UiPinonimageComponent implements OnInit {

 

  public slide: any;
  public clicked: any = '';

  @Input()
  set slidedata(val: any) {
    this.slide = val;
    // this.slide.content.options.forEach(element => {
    //   this.textFieldFormArray.push(new FormControl(false))
    // });
  }

  public mchoiceForm = new FormGroup({
    textField: new FormArray([]),
  });

  constructor() {

  }

  ngOnInit(): void {
  }

  get textFieldFormArray() {
    return this.mchoiceForm.controls.textField as FormArray;
  }
  onimageClick(evt) {
    var marker = document.getElementById("markerID");
    var xposition = (evt.layerX - (marker.offsetLeft) - marker.offsetWidth / 2);
    var yposition = (evt.layerY - marker.offsetTop - marker.offsetHeight / 2);
    marker.style.transform = "translate(" + xposition + "px," + yposition + "px)";  
    this.slide.content.answers = {
      x:xposition,
      y:yposition
    }
  }


}


