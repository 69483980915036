<div>
    <div>
      <div [formGroup]="mchoiceForm">
        <div style="text-align: center;">{{slide.content.description}}</div>
        <h2 style="text-align: center;padding: 30px">{{slide.content.question}}</h2>
        <div style="text-align: center;">
          <img *ngIf="this.slide.content.image && this.slide.content.image !== ''" src="{{this.slide.content.image}}"
           class="img-sec">
        </div>
        <div class="content-sec">
            <input type="text" name="textFieldOne" formControlName="textFieldOne" (change)="textChanged($event)">
       </div>
      </div>
    </div>
  </div>
  