import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-present-ui-wordcloud',
  templateUrl: './ui-wordcloud.component.html',
  styleUrls: ['./ui-wordcloud.component.scss']
})
export class UiWordcloudComponent implements OnInit {
  public slide: any;

  @Input()
  set slidedata(val: any) {
    this.slide = val;
    console.log(this.slide)
    let count = this.slide.content.options;
    console.log(count);
    for (let i = 0; i < count; i++) {
      this.textFieldFormArray.push(new FormControl(''))
    }
  }
  public mchoiceForm = new FormGroup({
    textField: new FormArray([]),
  });
  constructor() { }

  ngOnInit(): void {
  }

  get textFieldFormArray() {
    return this.mchoiceForm.controls.textField as FormArray;
  }
  public textChanged(evt) {
    if(!this.slide.content.multiplesubmit){
      this.slide.content.answers = this.mchoiceForm.value;
    }
  }
  public addMore() {
    if (this.slide.content.answers && this.slide.content.answers.textField) {
      this.slide.content.answers.textField = this.slide.content.answers.textField.concat(this.mchoiceForm.value.textField)
      this.slide.content.answers.textField = this.slide.content.answers.textField.filter(function (el) {
        return el != null;
      });
    } else {
      this.slide.content.answers = this.mchoiceForm.value;
    }
    this.mchoiceForm.reset();
  }
}
