<div>
    <div>
      <div [formGroup]="mchoiceForm">
        <h2 style="text-align: center;padding: 30px">{{slide.content.question}}</h2>
        <h4 style="text-align: center;padding: 30px">{{slide.content.subTitle}}</h4>
        <div style="text-align: center;">
            <div class="pin-img-container">
                <i class="fas fa-circle marker" id="markerID"></i>
                <img id="imageID" (click)="onimageClick($event)" *ngIf="this.slide.content.image && this.slide.content.image !== ''" src="{{this.slide.content.image}}"
                style="width: 100%;">
            </div>
        </div>
        <!-- <div id="target-box" (click)="testfn($event)">
        </div>
           <div id="target"> 
        </div> -->
      </div>
    </div>
  </div>
  