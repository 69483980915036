<div>
  <div>
    <div>
      <div style="text-align: center;">{{slide.content.description}}</div>
      <h2 style="text-align: center;padding: 30px">{{slide.content.question}}</h2>
      <div style="text-align: center;">
        <img *ngIf="this.slide.content.image && this.slide.content.image !== ''" src="{{this.slide.content.image}}"
          class="img-sec">
      </div>
      <div class="content-sec">
        <div *ngFor="let fld of this.slide.content.options; let i = index">
          <div><b>{{fld}}</b> <button class="btn {{options[i].disabled?'btn-gray':'btn-yellow'}}" style="float: right;"
              (click)="makedisabled(i);">Skip</button></div>
          <ngx-slider (mouseup)="textChanged($event)" [(value)]="scaleArray[i]" [options]="options[i]"></ngx-slider>
          <br><br>
          <br><br>
        </div>
      </div>
    </div>
  </div>
</div>
