import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-present-ui-big',
  templateUrl: './ui-big.component.html',
  styleUrls: ['./ui-big.component.scss']
})
export class UiBigComponent implements OnInit {

  
  public slide: any;
  public clicked: any = '';

  @Input()
  set slidedata(val: any) {
    this.slide = val;
    this.slide.content.options.forEach(element => {
      this.textFieldFormArray.push(new FormControl(false))
    });
  }

  public mchoiceForm = new FormGroup({
    textField: new FormArray([]),
  });

  constructor() {

  }

  ngOnInit(): void {
  }

  get textFieldFormArray() {
    return this.mchoiceForm.controls.textField as FormArray;
  }
  public textChanged(evt, index) {
    for(var i=0; i<this.slide.content.options.length;i++){
      if(i == index){

      } else {
        this.mchoiceForm.get('textField').value[i] = false;
      }
    }
    // this.slide.content.options.forEach(element => {
    //   this.textFieldFormArray.push(new FormControl(false))
    // });
    this.slide.content.answers = this.mchoiceForm.value;
    console.log(this.slide.content.answers);

  }
}
