import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Options } from "@angular-slider/ngx-slider";

@Component({
  selector: 'app-present-ui-scales',
  templateUrl: './ui-scales.component.html',
  styleUrls: ['./ui-scales.component.scss']
})
export class UiScalesComponent implements OnInit {

  public slide: any;
  public scaleArray: any = [];
  value: number = 5;
  options: any = [];
  @Input()
  set slidedata(val: any) {
    this.slide = val;
    let arTemp = [];
    arTemp.push(this.slide.content.extraConfig.dimensions.low);
    arTemp = arTemp.concat(this.slide.content.extraConfig.dimensions.mid)
    arTemp.push(this.slide.content.extraConfig.dimensions.high);
    this.slide.content.options.forEach(element => {
      this.scaleArray.push(0);

      let op: Options = {
        showTicksValues: true,
        disabled: false,
        stepsArray: arTemp
      };
      this.options.push(op);
    });
  
  }
  constructor() {

  }

  ngOnInit(): void {
  }
  public textChanged(evt) {
    this.slide.content.answers = this.scaleArray;
  }

  public makedisabled(i) {
   
    this.options[i] = {
      showTicksValues: true,
      disabled: !this.options[i].disabled,
      stepsArray: this.options[i].stepsArray
    };
  }
}
