<p>ui-hundredpoints works!</p>
<div>
    <div>
      <div [formGroup]="mchoiceForm">
        <h2 style="text-align: center;padding: 30px">{{slide.content.question}}</h2>
        <div style="text-align: center;">
          <img *ngIf="this.slide.content.image && this.slide.content.image !== ''" src="{{this.slide.content.image}}"
            class="img-sec">
        </div>
        <div class="content-sec" formArrayName="textField">
          <div *ngFor="let fld of textFieldFormArray.controls; let i = index">
            <label style="color: #000; font-size: 18px;">{{slide.content.options[i]}}</label>
            <div style="display: flex;">
              <button (click)="removeTen(i)" class="btn" style="background: #b9b9b9;font-weight: bold;" [disabled]="fld.value < 10?true:false">-10</button>
              <input min="0" max="100" class="form-control" style="" type="number" name="{{i}}" [formControlName]="i"
                (change)="textChanged($event, fld.value, i)">
              <button class="btn" style="background: #b9b9b9;font-weight: bold;" (click)="addTen(i)" [disabled]="pointsLeft === 0?true:false">+10</button>
            </div>
            <div>{{pointsLeft}} points left</div><br>
          </div>
          <!-- <button mat-button mat-raised-button color="primary">Update</button> -->
        </div>
        
      </div>
    </div>
  </div>
  