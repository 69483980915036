
<header>
    <nav class="navbar navbar-expand-lg wow fadeIn" data-wow-delay="100ms" data-wow-duration="1s" id="main_navbar">
        <div class="container-fluid">
           <a class="navbar-brand" href="#/"><img src="assets/images/logo.jpg"></a>
            
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
               <ul class="navbar-nav">
                   <li class="nav-item">
                       <a class="nav-link" href="#/my-presentations">Your presentations</a>
                   </li>
                   <li class="nav-item">
                       <a class="nav-link" href="javascript:void(0)">Inspiration</a>
                   </li>
                   <li class="nav-item">
                       <a class="nav-link" href="javascript:void(0)">Branding & colors</a>
                   </li>
                   <li class="nav-item dropdown">
                       <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Account </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><a class="dropdown-item" href="javascript:void(0)">Profile</a></li>
                            <li><a class="dropdown-item" href="javascript:void(0)" (click)="logout()">Log Out</a></li>
                        </ul>
                   </li>
                   <li class="nav-item">
                       <a class="nav-link" href="javascript:void(0)">Help</a>
                   </li>
               </ul>
                <div class="top-profile ml-auto d-flex align-items-center">
                   <span  style="margin-right: 10px; width: 200px;">
                     <img src="assets/images/unnamed.png" class="prof-img" style="float: left;">
                    <h5 class="m-0">
                        <span>{{user.name}}</span>
                        <small>{{user.email}}</small>
                    </h5></span>
                    <i class="fas fa-sign-out-alt" title="Logout" style="cursor: pointer;" (click)="logout()"></i>
                </div>
           </div>
            
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
               <span class="navbar-toggler-icon"></span>
            </button>
        </div>
   </nav>
</header>