import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-present-ui-selectanswer',
  templateUrl: './ui-selectanswer.component.html',
  styleUrls: ['./ui-selectanswer.component.scss']
})
export class UiSelectanswerComponent implements OnInit {

  public slide: any;

  public audio1: HTMLAudioElement;
  // @ViewChild('demoBtn') triggerModalBtn: ElementRef<HTMLElement>;
  @Input()
  set slidedata(val: any) {
    this.slide = val;
    this.slide.content.options.forEach(element => {
      this.textFieldFormArray.push(new FormControl(false))
    });
    // let el: HTMLElement = this.triggerModalBtn.nativeElement;
    // el.click();
    this.audio1 = new Audio("http://crerater.com/dev/assets/mp3/"+this.slide.content.extraConfig.audio);
    this.audio1.play();
  }

  public mchoiceForm = new FormGroup({
    textField: new FormArray([]),
  });

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.audio1.pause();
    this.audio1.currentTime = 0;
  }

  get textFieldFormArray() {
    return this.mchoiceForm.controls.textField as FormArray;
  }
  public textChanged(evt) {
    console.log(this.slide);
    this.slide.content.answers = this.mchoiceForm.value;
  }
  public ansSelected(index) {
    this.slide.content.answers = [];
    var i = 0;
    this.slide.content.options.forEach(element => {
      if (i === index) {
        this.slide.content.answers.push(true);
      } else {
        this.slide.content.answers.push(false);
      }
      i++
    });

  }

}
