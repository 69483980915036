<header>
  <nav class="navbar navbar-expand-lg wow fadeIn" data-wow-delay="100ms" data-wow-duration="1s">
    <div class="container-fluid">
      <a class="navbar-brand" href="#/"><img src="assets/images/logo.png"></a>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item ">
            <a class="nav-link" href="#/features">Features</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#/solutions">Solutions</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#/pricing">Pricing</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#/blog">Blog</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#contact">Contact</a>
          </li>
        </ul>
        <div class="header-btns ml-auto" *ngIf="!logged">
          <a href="javascript:void(0)" class="btn btn-yellow" data-toggle="modal" data-target="#loginModal">LOG IN</a>
          <a href="javascript:void(0)" class="btn btn-gray" data-toggle="modal" data-target="#signupModal">SIGN UP</a>
        </div>
       
        <div class="top-profile ml-auto d-flex align-items-center" *ngIf="logged">
          <div class="header-btns ml-auto" style="margin-right: 10px;" *ngIf="logged">
            <a href="#/my-presentations" class="btn btn-yellow">My Presentations</a>
          </div>
          <span  style="margin-right: 10px; width: 200px;">
            <img src="assets/images/unnamed.png" class="prof-img" style="float: left;">
           <h5 class="m-0">
               <span>{{user.name}}</span>
               <small>{{user.email}}</small>
           </h5></span>
           <i class="fas fa-sign-out-alt" title="Logout" style="cursor: pointer;" (click)="logout()"></i>
       </div>
      </div>

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
  </nav>
</header>