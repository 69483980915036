import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public logged: boolean = false;
  public user: any = {
    name: '',
    email: ''
  };
  constructor(public router: Router) {
    if (localStorage.getItem('userLogged') && localStorage.getItem('userLogged') === 'true') {
      this.logged = true;
      this.user = {
        name: localStorage.getItem('name'),
        email: localStorage.getItem('email')
      }
    }
  }

  ngOnInit(): void {
  }

  public logout() {
    localStorage.setItem('userLogged', 'false');
    localStorage.setItem('authtoken', '');
    localStorage.setItem('name', '');
    localStorage.setItem('email', '');
    location.reload();
    // this.router.navigate(['/']);
  }

}
