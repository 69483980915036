import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-present-ui-hundredpoints',
  templateUrl: './ui-hundredpoints.component.html',
  styleUrls: ['./ui-hundredpoints.component.scss']
})
export class UiHundredpointsComponent implements OnInit {

  public slide: any;
  public pointsLeft: number = 100;

  @Input()
  set slidedata(val: any) {
    this.slide = val;
    this.slide.content.options.forEach(element => {
      this.textFieldFormArray.push(new FormControl(null))
    });
  }

  public mchoiceForm = new FormGroup({
    textField: new FormArray([]),
  });

  constructor() {

  }

  ngOnInit(): void {
  }

  get textFieldFormArray() {
    return this.mchoiceForm.controls.textField as FormArray;
  }
  public textChanged(evt, valfrom, index) {
    var curVal = 0;
    this.mchoiceForm.get('textField').value.forEach(element => {
      curVal = curVal + element;
    });
    if ((curVal) > 100) {
      curVal = curVal - valfrom;
      if (curVal !== 100) {
        this.textFieldFormArray.at(index).setValue(100 - curVal);
        curVal += 100 - curVal;
      } else {
        this.textFieldFormArray.at(index).setValue(null);
      }
    }
    this.pointsLeft = 100 - curVal;

    this.slide.content.answers = this.mchoiceForm.value;
  }
  public addTen(index) {
    var temp = this.textFieldFormArray.at(index).value;
    this.textFieldFormArray.at(index).setValue(temp + 10);
    this.textChanged('', temp + 10, index);
  }
  public removeTen(index){
    var temp = this.textFieldFormArray.at(index).value;
    this.textFieldFormArray.at(index).setValue(temp - 10);
    this.textChanged('', temp - 10, index);
  }

}
