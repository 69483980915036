<div>
    <div>
      <div [formGroup]="mchoiceForm">
        <div style="text-align: center;">{{slide.content.description}}</div>
        <h2 style="text-align: center;padding: 30px">{{slide.content.question}}</h2>
        <div style="text-align: center;">
          <img *ngIf="this.slide.content.image && this.slide.content.image !== ''" src="{{this.slide.content.image}}"
            class="img-sec">
        </div>
        <div style="margin: 0 auto;width: fit-content;" formArrayName="textField">
          <br>
          <!-- <div *ngFor="let fld of textFieldFormArray.controls; let i = index">
              <input style="margin-right: 20px;" type="checkbox" name="{{i}}" [formControlName]="i" (change)="textChanged($event)"> <label style="font-size: 20px;">{{slide.content.options[i]}}
              </label>
            </div> -->
          <!-- <button mat-button mat-raised-button color="primary">Update</button> -->
  
          <div *ngFor="let fld of slide.content.options; let i = index">
              <button class="btn btn-gray" style="padding: 10px;margin-bottom: 10px; min-width: 200px;" (click)="ansSelected(i)">{{fld}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  