import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({ selector: '[runScripts]' })
export class RunScriptsDirective implements OnInit {
    constructor(private elementRef: ElementRef) { }
    ngOnInit(): void {
        const imgs = <HTMLImageElement[]>this.elementRef.nativeElement.getElementsByTagName('img');
        const imgsInitialLength = imgs.length;
        for (let i = 0; i < imgsInitialLength; i++) {
            const img = imgs[i];
            const imgCopy = <HTMLImageElement>document.createElement('img');
            imgCopy.src = img.src;
            imgCopy.width = 500;
            imgCopy.height = 300;
            img.parentNode.replaceChild(imgCopy, img);
        }
        setTimeout(() => { // wait for DOM rendering
            this.reinsertScripts();
        });
    }
    reinsertScripts(): void {
     
            const scripts = <HTMLScriptElement[]>this.elementRef.nativeElement.getElementsByTagName('script');
            const scriptsInitialLength = scripts.length;
            for (let i = 0; i < scriptsInitialLength; i++) {
                const script = scripts[i];
                const scriptCopy = <HTMLScriptElement>document.createElement('script');
                scriptCopy.type = script.type ? script.type : 'text/javascript';
                if (script.innerHTML) {
                    scriptCopy.innerHTML = script.innerHTML;
                } else if (script.src) {
                    scriptCopy.src = script.src;
                }
                scriptCopy.async = false;
                script.parentNode.replaceChild(scriptCopy, script);
            }
        
    }
}