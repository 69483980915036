import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-present-ui-typeanswer',
  templateUrl: './ui-typeanswer.component.html',
  styleUrls: ['./ui-typeanswer.component.scss']
})
export class UiTypeanswerComponent implements OnInit {
  public slide: any;
  public audio1: HTMLAudioElement;
  // @ViewChild('demoBtn') triggerModalBtn: ElementRef<HTMLElement>;
 

  @Input()
  set slidedata(val: any) {
    this.slide = val;
    this.audio1 = new Audio("http://crerater.com/dev/assets/mp3/"+this.slide.content.extraConfig.audio);
    this.audio1.play();
  }

  public mchoiceForm = new FormGroup({
    textFieldOne: new FormControl(null),
  });

  constructor(private toastr: ToastrService) {
    console.log(this.mchoiceForm);
  }

  ngOnInit(): void {
    // let el: HTMLElement = this.triggerModalBtn.nativeElement;
    // el.click();
    // this.audio1 = new Audio("http://crerater.com/dev/assets/mp3/"+this.slide.content.extraConfig.audio);
    // this.audio1.play();
  }
  ngOnDestroy(): void {
    this.audio1.pause();
    this.audio1.currentTime = 0;
  }
  public textChanged(evt) {
    this.slide.content.answers = this.mchoiceForm.value.textFieldOne;
  }


}
