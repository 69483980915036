import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PresentationService } from 'src/app/pages/presentation/presentation.service';
import { RealtimeService } from 'src/app/services/realtime.service';
declare var $: any;

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {
  public pageLoader: boolean = true;

  public slideIx: any = 0

  public slides: any = [];
  public activeIndex: any = 0;
  public id: any = '';
  public submitted: boolean = false;
  public activetheme: any = {
    title: '',
    themeBgColor: '#ffffff',
    themeBgImage: '',
    themeLogoImage: 'assets/images/logo.png',
    themeColor: '#6f6f6f',
    themeLineColor: '#000',
    themeVisColor: [
      "#fdc003", "#707070", "#cccccc", "#000000"
    ]
  }
  public qaonallslides: boolean = false;
  public hideIndex = [];
  public isWaiting: boolean = false;
  public activePace: string = 'presenter';
  constructor(private router: Router, private route: ActivatedRoute, public presentService: PresentationService, private toastr: ToastrService, public realtimeService: RealtimeService) {

    this.route.params.subscribe(params => {
      if (params['surveyid']) {
        this.id = params['surveyid'];
        this.getDetails();

      } else {
        setTimeout(() => {
          $('#surveyModal').modal('show');
        }, 200);
      }

    });
  }

  ngOnInit(): void {
    this.realtimeService.slideChanged().subscribe((ind: any) => {
      if (this.activePace === 'presenter') {
        $('#slideChanged').modal("hide");
        console.log('ind', ind);
        console.log('this.slides.length', this.slides.length);
        console.log('this.activeIndex', this.activeIndex);

        if (ind !== undefined && ind !== this.activeIndex) {
          if (ind !== this.slides.length) {
            this.slideIx = ind;
            if (this.isWaiting) {
              this.activeIndex = this.slideIx;
              this.isWaiting = false;
            } else {
              $('#slideChanged').modal("show");
            }

            console.log("switched");
          } else {
            if ((this.activeIndex + 1) == this.slides.length) {
              this.submitted = true;
            }
            this.activeIndex = this.activeIndex + 1;
          }


        } else {
          console.log("reached last");

        }
      }

      // alert(message);
      // this.messageList.push(message);
    })
    this.realtimeService.started().subscribe((ind: any) => {
      if (ind !== undefined) {
        const index = this.hideIndex.indexOf(ind);
        if (index > -1) {
          this.hideIndex.splice(index, 1);
        }
      }
    })
    this.realtimeService.stopped().subscribe((ind: any) => {
      if (ind !== undefined) {
        this.hideIndex.push(ind);
      }
    })
  }
  public getDetails() {
    this.id = this.id.replace(/ /g,'');
    this.pageLoader = true;
    this.realtimeService.joinSurvey(this.id);
    this.presentService.getPresentationbyKey(this.id).subscribe((res: any) => {
      if (res.data !== null) {
        this.slides = res.data.slides;
        this.activetheme = res.data.theme;
        this.activePace = res.data.pace;
        if (this.slides.length && (this.slides.findIndex(x => x.type === 'qa') !== -1) && this.slides.find(x => x.type === 'qa').content.extraConfig && this.slides.find(x => x.type === 'qa').content.extraConfig.onallslides === 'yes') {
          this.qaonallslides = true;
        }

      } else {

        this.toastr.error('Failed', 'Invalide Code', { positionClass: 'toast-top-center' });
        setTimeout(() => {
          $('#surveyModal').modal('show');
        }, 1000);
      }
      this.pageLoader = false;

    });
  }
  public continueToNext() {
    this.realtimeService.slideAnswered(this.id, this.activeIndex, this.slides[this.activeIndex].content.answers);
    this.presentService.saveSurvey({ id: this.id, slides: JSON.stringify(this.slides), indexed: this.activeIndex }).subscribe((res: any) => {
    });


    if (this.activePace === 'presenter') {
      if (this.slideIx == this.activeIndex) {
        this.isWaiting = true;

      } else {
        if ((this.activeIndex + 1) == this.slides.length) {
          this.submitted = true;
        }
        this.activeIndex = this.activeIndex + 1;
      }
    } else {
      if ((this.activeIndex + 1) == this.slides.length) {
        this.submitted = true;
      }
      this.activeIndex = this.activeIndex + 1;
    }




  }
  public finalsubmit() {
    this.submitted = true;
    this.presentService.saveSurvey({ id: this.id, slides: JSON.stringify(this.slides), indexed: this.activeIndex }).subscribe((res: any) => {
    });
  }
  public continueBtn(evt) {
    if (this.id !== '') {
      this.getDetails();
      setTimeout(() => {
        $('#surveyModal').modal('hide');
      }, 200);
    } else {
      this.toastr.warning('Warning', 'Please enter the code', { positionClass: 'toast-top-center' });
    }
  }
  public getQASlide() {
    return this.slides.find(x => x.type === 'qa');
  }
  public closeMdl() {
    $('#slideChanged').modal("hide");
  }
}
