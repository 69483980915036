<svg *ngIf="pageLoader" class="pagespinner" viewBox="0 0 50 50">
  <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="8"></circle>
</svg>
<div class="container-fluid {{submitted?'lastpage':''}}" style="height: 100vh;"
  [ngStyle]="{'background-color': activetheme.themeBgColor, 'background-image': 'url('+activetheme.themeBgImage+')',  'background-blend-mode': (activetheme.themeBgColor !== '')?'overlay':'inherit', 'background-size': 'cover', 'color': activetheme.themeColor}">
  <div class="container">
    <div class="row">
      <div class="col-md-12" style="text-align: center;margin-top: 10px;"><img src="assets/images/logo.png"></div>
      <div class="col-md-12" *ngIf="!submitted">
        <div *ngFor="let slide of slides; let i = index">
          <div *ngIf="this.activeIndex === i && slide.type !=='blank'"
            class="{{this.activeIndex === i?'presentation-show':'presentation-hide'}} ">
            <app-present-ui-multiplechoice *ngIf="slide.type === 'multiple_choice'" [slidedata]="slide">
            </app-present-ui-multiplechoice>
            <app-present-ui-wordcloud *ngIf="slide.type === 'word_cloud'" [slidedata]="slide">
            </app-present-ui-wordcloud>
            <app-present-ui-openended *ngIf="slide.type === 'open_ended'" [slidedata]="slide">
            </app-present-ui-openended>
            <app-present-ui-scales *ngIf="slide.type === 'scales'" [slidedata]="slide">
            </app-present-ui-scales>
            <app-present-ui-heading *ngIf="slide.type === 'heading'" [slidedata]="slide">
            </app-present-ui-heading>
            <app-present-ui-paragraph *ngIf="slide.type === 'paragraph'" [slidedata]="slide">
            </app-present-ui-paragraph>
            <app-present-ui-bullets *ngIf="slide.type === 'bullets'" [slidedata]="slide">
            </app-present-ui-bullets>
            <app-present-ui-image *ngIf="slide.type === 'image'" [slidedata]="slide">
            </app-present-ui-image>
            <app-present-ui-image *ngIf="slide.type === 'document'" [slidedata]="slide">
            </app-present-ui-image>
            <app-present-ui-selectanswer *ngIf="slide.type === 'selectAnswer'" [slidedata]="slide">
            </app-present-ui-selectanswer>
            <app-present-ui-typeanswer *ngIf="slide.type === 'typeAnswer'" [slidedata]="slide">
            </app-present-ui-typeanswer>
            <app-present-ui-ranking *ngIf="slide.type === 'ranking'" [slidedata]="slide"></app-present-ui-ranking>
            <app-present-ui-qa *ngIf="slide.type === 'qa'" [slidedata]="slide"></app-present-ui-qa>
            <app-present-ui-big *ngIf="slide.type === 'big'" [slidedata]="slide"></app-present-ui-big>
            <app-present-ui-quote *ngIf="slide.type === 'quote'" [slidedata]="slide"></app-present-ui-quote>
            <app-present-ui-number *ngIf="slide.type === 'number'" [slidedata]="slide"></app-present-ui-number>
            <app-present-ui-video *ngIf="slide.type === 'video'" [slidedata]="slide"></app-present-ui-video>
            <app-present-ui-winner *ngIf="slide.type === 'whooWillWin'" [slidedata]="slide"></app-present-ui-winner>
            <app-present-ui-pinonimage *ngIf="slide.type === 'pinonimage'" [slidedata]="slide">
            </app-present-ui-pinonimage>
            <app-present-ui-hundredpoints *ngIf="slide.type === '100points'" [slidedata]="slide">
            </app-present-ui-hundredpoints>
            <app-present-ui-quickform *ngIf="slide.type === 'quickForm'" [slidedata]="slide">
            </app-present-ui-quickform>
            <div class="btnarea">
              <div *ngIf="this.isWaiting" style="
              position: fixed;
              height: 100vh;
              width: 100%;
              z-index: 99;
              top: 0px;
              left: 0px;
              color: #fff;
              background: #00000091;
          ">
                <img src="assets/images/Group2.png" style="width: 20%;border: 5px solid;margin-top: 10%;">
                <p style="font-size: 25px;">Please wait for the presenter to show the next slide.</p>
              </div>
              <button [disabled]="this.hideIndex.indexOf(this.activeIndex) !== -1 || this.isWaiting"
                class="btn btn-yellow" (click)="continueToNext()">Submit</button>


              <!-- <button class="btn btn-yellow" *ngIf="(this.activeIndex + 1) == this.slides.length"
                (click)="continueToNext()">Submit</button> -->
            </div>
            <!-- <span *ngIf="this.hideIndex.indexOf(this.activeIndex) !== -1">
              Voting closed
            </span> -->
            <div *ngIf="this.hideIndex.indexOf(this.activeIndex) !== -1"
              style="border-left: 8px solid #fdc003;background: #fdc0033b;padding: 10px;font-size: 15px;border-radius: 5px; width: 45%; margin: 0 auto;">
              <h4>Voting is closed</h4>
              <p>Please wait or ask the presenter to open voting.</p>
            </div>
          </div>
        </div>
        <app-present-ui-qa *ngIf="qaonallslides && slides[this.activeIndex].type !== 'qa'" [slidedata]="getQASlide()">
        </app-present-ui-qa>

      </div>

    </div>

  </div>
  <div class="" style="text-align: center;font-size: 35px;margin-top: 100px;color: #fff;" *ngIf="submitted">
    Thank you for your participation!
    <div>
      <a href="#/" style="font-size: 20px;">Exit and vote on another presentation</a>
    </div>
  </div>
</div>


<!-- survey Modal -->
<div class="modal fade" tabindex="-1" role="dialog" id="surveyModal" data-backdrop="static" data-keyboard="false">
  <p style="text-align: center;font-size: 35px;font-weight: bold;padding-top: 15px;"> <img _ngcontent-shw-c99=""
      src="assets/images/logo.png">
  </p>
  <div class="modal-dialog" style="
  display: flex;
  align-items: center;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
">
    <div class="modal-content otr" style="background: transparent;">
      <div class="login-container" style="
      padding-left: 10px;
      padding-right: 10px;
  ">
        <div class="row no-gutters">
          <div class="col-sm-12 left-bx" style="background: transparent;">
            <div class="login-bx">
              <div class="form-group" style="display: flex;">
                <div class="search__form-control-aside--left" aria-hidden="true">
                  #
                </div>
                <!-- <label style="font-size: 17px; font-weight: bold;">Please enter the code *</label> -->
                <input style="width: 100%; height: 60px; padding-right: 36px;padding-left: 60px; font-size: 18px;"
                  type="text" name="id" [(ngModel)]="id" class="form-control" placeholder="enter code here">
                <button style="margin-left: -50px;margin-top: 10px;" class="btn btn-yellow btn-block"
                  (click)="continueBtn($event)"><i class="fas fa-arrow-right"></i></button>
              </div>

            </div>
          </div>
          <div class="col-sm-12">
            <p style="width: 100%; text-align: center;">By using this app I agree to the <a
                href="javascript:void(0)">Acceptable Use</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p style="text-align: center;padding-top: 15px;bottom: 0;width: 100%;position: absolute;">
    <a href="javascript:void(0)">Privacy Policy</a>&nbsp;
    <a href="javascript:void(0)">Privacy Settings</a>


  </p>
</div>


<div class="modal fade" data-backdrop="static" data-keyboard="false" id="slideChanged" role="dialog"
  aria-labelledby="importpresentationLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md" style="align-items: flex-start;
  margin-top: 0px;
  max-width: 100%;">
    <div class="modal-content rounded-0" style="background: transparent;">
      <div class="modal-body" style="padding:0px">
        <div style="text-align: center;font-size: 20px;font-weight: bold;padding-top: 15px;background: #fff;
        box-shadow: black 0px 2px 6px;">
          The presenter has changed slide.
          <div style="margin-bottom: 10px; margin-top: 10px;">
            <button class="btn btn-gray" data-dismiss="modal" aria-label="Close">Cancel</button>
            <button class="btn btn-yellow" style="margin-left: 20px;" data-dismiss="modal" aria-label="Close"
              (click)="this.activeIndex = this.slideIx;">Go to slide</button>
            <br>
            <br>
          </div>
        </div>
      </div>
      <!-- <div class="modal-footer">
        <button class="btn btn-gray" data-dismiss="modal" aria-label="Close"
          (click)="this.delThemeId = ''">Cancel</button>
        <button class="btn btn-yellow" (click)="this.themeDelete(this.delThemeId)">Yes, I am sure</button>
      </div> -->
    </div>
  </div>
</div>



<!-- <div class="modal fade" tabindex="-1" role="dialog" id="slideChanged" data-backdrop="static" data-keyboard="false">
  <div style="text-align: center;font-size: 20px;font-weight: bold;padding-top: 15px;background: #fff;
  box-shadow: black 0px 2px 6px;">
    The presenter has changed slide.
   <div style="margin-bottom: 10px; margin-top: 10px;">
    <button class="btn btn-gray" (click)="closeMdl()">Cancel</button>
    <button class="btn btn-yellow" style="margin-left: 20px;" (click)="this.activeIndex = this.slideIx; closeMdl()">Go to slide</button>
    <br>
    <br>
  </div> 
  </div>
</div> -->
