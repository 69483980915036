<div>
  <div [formGroup]="mchoiceForm">
    <div formArrayName="textField">
      <div style="text-align: center;">{{slide.content.description}}</div>
      <h2 style="text-align: center;padding: 30px">{{slide.content.question}}</h2>
      <div style="text-align: center;">
        <img *ngIf="this.slide.content.image && this.slide.content.image !== ''" src="{{this.slide.content.image}}" class="img-sec">
      </div>
      <div class="content-sec">
        <div class="field-set" *ngFor="let fld of textFieldFormArray.controls; let i = index">
          <input type="text" name="{{i}}" formControlName="{{i}}"
            (change)="textChanged($event)">
        </div>
        <p *ngIf="this.slide.content.multiplesubmit" style="margin-top: 10px;">You can submit multiple
          answers</p>
        <div *ngIf="this.slide.content.answers && this.slide.content.answers.textField && this.slide.content.multiplesubmit">
                    <!-- {{this.slide.content.answers.textField}} -->
                    <span *ngFor="let s of this.slide.content.answers.textField;  let last = last">
                      <span *ngIf="s !== ''">
                      {{s}}
                      <span *ngIf="!last && s !== ''">, </span>
                      </span>
                    </span>
        </div>
        <br>
        <button *ngIf="this.slide.content.multiplesubmit" (click)="addMore()" class="btn btn-gray"
          >Add</button>
      </div>

    </div>
  </div>
</div>
