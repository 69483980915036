import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Options } from "@angular-slider/ngx-slider";

@Component({
  selector: 'app-present-ui-ranking',
  templateUrl: './ui-ranking.component.html',
  styleUrls: ['./ui-ranking.component.scss']
})
export class UiRankingComponent implements OnInit {

  public slide: any;
  public scaleArray: any = [];
  public loopPurpose: any = [];
  public dropdownoptions: any = [];
  value: number = 5;
  options: Options = {
    showTicksValues: true,
    stepsArray: [
      { value: 1, legend: "Strongly disagree" },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5, legend: "Strongly Agree" },
    ]
  };

  @Input()
  set slidedata(val: any) {
    this.slide = val;
    this.loopPurpose.push('');
    // this.dropdownoptions = JSON.parse(JSON.stringify(this.slide.content.options));
    this.slide.content.options.forEach(element => {
      this.scaleArray.push('');
      this.dropdownoptions.push({'disable': 'no', 'value': element})
    });
  }
  constructor() {

  }

  ngOnInit(): void {
  }
  public textChanged() {
    this.slide.content.answers = this.scaleArray;
    console.log(this.slide.content.answers);
  }
  public dropSelect(evt, i) {
    if(this.dropdownoptions.findIndex(x => x.value ===evt) !== -1){
      this.dropdownoptions[this.dropdownoptions.findIndex(x => x.value ===evt)].disable = 'yes';
    }
    if(this.scaleArray[i] !== '' && this.scaleArray[i] !== 'select') {
      this.dropdownoptions[this.dropdownoptions.findIndex(x => x.value ===this.scaleArray[i])].disable = 'no';
    }
    this.scaleArray[i] = evt;
    if(this.slide.content.options.length > this.loopPurpose.length && i === (this.loopPurpose.length-1)){
      this.loopPurpose.push('');
    }
    this.textChanged();
  }

}
