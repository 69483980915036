<div>
  <div>
    <div>
      <div style="text-align: center;">{{slide.content.description}}</div>
      <h2 style="text-align: center;padding: 30px">{{slide.content.question}}</h2>
      <div style="text-align: center;">
        <img *ngIf="this.slide.content.image && this.slide.content.image !== ''" src="{{this.slide.content.image}}"
          class="img-sec">
      </div>
      <div style="margin: 0 auto;" style="text-align: center;">
        <p>Click the button to participate!</p>
        <button class="btn btn-warning" data-toggle="modal" data-target="#qamodel">Open Q&A</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" tabindex="-1" role="dialog" id="qamodel">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="container" style="padding: 20px;">
        <div class="row no-gutters">
          <select style="width: 100%;padding: 10px;margin-bottom: 10px;">
            <option class="opt">Recent</option>
            <option class="opt">Top Questions</option>
          </select>
          <p *ngIf="!qaList.length">No data found</p>
          <div *ngFor="let ls of this.qaList; let i = index" style="width: 100%;">
            <div *ngIf="this.slide.content.extraConfig && this.slide.content.extraConfig.domoderate && ls.status === 'approved'"
              style=" border-bottom: 2px solid;padding: 10px;"> {{ls.value}}
              <i (click)="liked(i)" class="fas fa-thumbs-up" style="
              float: right;
              border: 1px solid;
              padding: 9px;
              cursor: pointer;
              border-radius: 50%;
          "></i>
            </div>

            <div *ngIf="!(this.slide.content.extraConfig && this.slide.content.extraConfig.domoderate)" style=" border-bottom: 2px solid;padding: 10px;">
              {{ls.value}}
              <i class="fas fa-thumbs-up" style="
              float: right;
              border: 1px solid;
              padding: 9px;
              cursor: pointer;
              border-radius: 50%;
          "></i>
            </div>
          </div>

          <div *ngFor="let ls of curList" style="width: 100%;">
            <div *ngIf="this.slide.content.extraConfig && this.slide.content.extraConfig.domoderate" style=" border-bottom: 2px solid;padding: 10px;">
              {{ls.value}}
              <i class="fas fa-thumbs-up" style="
              float: right;
              border: 1px solid;
              padding: 9px;
              cursor: pointer;
              border-radius: 50%;
          "></i>
            </div>
          </div>
        </div>
        <div class="row no-gutters" style="text-align: center;margin-top: 25px;">
          <textarea style="width: 100%;margin-bottom: 25px;" [(ngModel)]="textareaval"></textarea>
          <button class="btn btn-gray" style="margin: 0 auto;" data-dismiss="modal">Close</button>
          <button class="btn btn-warning" style="margin: 0 auto;" (click)="addToList()">Submit</button>
        </div>
      </div>
    </div>
  </div>
</div>
