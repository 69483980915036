import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Options } from "@angular-slider/ngx-slider";

@Component({
  selector: 'app-present-ui-qa',
  templateUrl: './ui-qa.component.html',
  styleUrls: ['./ui-qa.component.scss']
})
export class UiQaComponent implements OnInit {

  public slide: any;
  public scaleArray: any = [];
  public qaList: any = [];
  public qaNewList: any = [];

  public curList: any = [];

  public textareaval: string = '';
  value: number = 5;
  options: Options = {
    showTicksValues: true,
    stepsArray: [
      { value: 1, legend: "Strongly disagree" },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5, legend: "Strongly Agree" },
    ]
  };

  @Input()
  set slidedata(val: any) {
    this.slide = val;
    this.slide.content.options.forEach(element => {
      this.scaleArray.push(0);
    });
    this.qaList = [];
    if (this.slide.content.extraConfig && this.slide.content.extraConfig.seeothersans) {
      this.slide.answers.forEach(element => {
        if (element.length) {
          element.forEach(elm => {
            this.qaList.push(elm);
          });
        }
      });
    }
  }
  constructor() {

  }

  ngOnInit(): void {
  }
  public textChanged(evt) {
    this.slide.content.answers = this.qaNewList;
  }
  public addToList() {
    if (this.textareaval !== '') {
      this.qaList.push({value:this.textareaval});
      this.qaNewList.push({value:this.textareaval, status:'initial', datetime: new Date(), likes: 0});
      this.curList.push({value:this.textareaval, status:'initial', datetime: new Date(), likes: 0});
      this.textareaval = '';
      this.textChanged('');
    }
  }
  public liked(index) {
    console.log(this.slide);
  }

}
