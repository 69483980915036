<div>
    <div>
      <div [formGroup]="mchoiceForm" style="text-align: center;">
        <h2 style="text-align: center;padding: 30px">{{slide.content.question}}</h2>
        <h4 style="text-align: center;padding: 30px">{{slide.content.subTitle}}</h4>
        <img *ngIf="slide.content.image" src="{{this.slide.content.image}}" style="width: 70%;max-height: 500px;">
  
        <div style="margin: 0 auto;width: fit-content;min-width: 300px;" formArrayName="textField"
          class="align-items-center mb-1">
          <div class="form-group" *ngFor="let fld of textFieldFormArray.controls; let i = index">
            <label style="font-size: 20px; text-align: left; width: 100%;">{{slide.content.options[i].fieldname}}
            </label>
            <input class="form-control" *ngIf="slide.content.options[i].fieldtype === 'text'" style="margin-right: 20px;"
              type="text" name="{{i}}" [formControlName]="i" (change)="textChanged($event, i)">
  
            <input class="form-control" *ngIf="slide.content.options[i].fieldtype === 'email'" style="margin-right: 20px;"
              type="email" name="{{i}}" [formControlName]="i" (change)="textChanged($event, i)">
            <input class="form-control" *ngIf="slide.content.options[i].fieldtype === 'date'" style="margin-right: 20px;"
              type="date" name="{{i}}" [formControlName]="i" (change)="textChanged($event, i)">
          </div>
        </div>
      </div>
    </div>
  </div>
  