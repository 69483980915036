import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { EmbedVideoService } from 'ngx-embed-video';
@Component({
  selector: 'app-present-ui-video',
  templateUrl: './ui-video.component.html',
  styleUrls: ['./ui-video.component.scss']
})
export class UiVideoComponent implements OnInit {

  public slide: any;
  public clicked: any = '';
  public videourl = '';
  public isSafeHtml = false;



  @Input()
  set slidedata(val: any) {
    var mwidth = 500;
    if(window.innerWidth < 767){
      mwidth = window.innerWidth - 60;
    }
    this.slide = val;
    this.slide.content.options.forEach(element => {
      this.textFieldFormArray.push(new FormControl(false))
    });
    if (this.slide.content.subTitle && this.slide.content.subTitle !== '') {
      if (this.slide.content.subTitle.indexOf('youtube') !== -1 || this.slide.content.subTitle.indexOf('dailymotion') !== -1 || this.slide.content.subTitle.indexOf('vimeo') !== -1) {
        this.videourl = this.embedService.embed(this.slide.content.subTitle, { attr: { width: mwidth, height: 300 } });
        this.isSafeHtml = true;
      } else {
        this.videourl = this.slide.content.subTitle;
      }
    }
  }

  public mchoiceForm = new FormGroup({
    textField: new FormArray([]),
  });

  constructor(private sanitizer: DomSanitizer, private embedService: EmbedVideoService) {

  }

  ngOnInit(): void {
  }

  get textFieldFormArray() {
    return this.mchoiceForm.controls.textField as FormArray;
  }
  public textChanged(evt, index) {
    for(var i=0; i<this.slide.content.options.length;i++){
      if(i == index){

      } else {
        this.mchoiceForm.get('textField').value[i] = false;
      }
    }
    // this.slide.content.options.forEach(element => {
    //   this.textFieldFormArray.push(new FormControl(false))
    // });
    this.slide.content.answers = this.mchoiceForm.value;
    console.log(this.slide.content.answers);

  }
  renderYoutubeSrc(url) {
    var a = url.split('v=');
    var url2 =  'https://www.youtube.com/embed/' + a[a.length - 1];
    return this.sanitizer.bypassSecurityTrustResourceUrl(url2);
  }

}
