import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { EmbedVideo } from 'ngx-embed-video';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChartsModule } from 'ng2-charts';
import { TagCloudModule } from 'angular-tag-cloud-module';
import { CommonService } from './common.service';
import { PresentationService } from './pages/presentation/presentation.service';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { PresentationHeaderComponent } from './common/presentation/header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiMultiplechoiceComponent } from './pages/presentation/ui/ui-multiplechoice/ui-multiplechoice.component';
import { UiWordcloudComponent } from './pages/presentation/ui/ui-wordcloud/ui-wordcloud.component';
import { UiOpenendedComponent } from './pages/presentation/ui/ui-openended/ui-openended.component';
import { UiScalesComponent } from './pages/presentation/ui/ui-scales/ui-scales.component';
import { HttpClientModule } from '@angular/common/http';
import { SurveyComponent } from './ui/survey/survey.component';
import { importType } from '@angular/compiler/src/output/output_ast';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { UiHeadingComponent } from './pages/presentation/ui/ui-heading/ui-heading.component';
import { UiParagraphComponent } from './pages/presentation/ui/ui-paragraph/ui-paragraph.component';
import { UiBulletsComponent } from './pages/presentation/ui/ui-bullets/ui-bullets.component';
import { UiImageComponent } from './pages/presentation/ui/ui-image/ui-image.component';
import { UiSelectanswerComponent } from './pages/presentation/ui/ui-selectanswer/ui-selectanswer.component';
import { UiTypeanswerComponent } from './pages/presentation/ui/ui-typeanswer/ui-typeanswer.component';
import { UiRankingComponent } from './pages/presentation/ui/ui-ranking/ui-ranking.component';
import { UiQaComponent } from './pages/presentation/ui/ui-qa/ui-qa.component';
import { UiBigComponent } from './pages/presentation/ui/ui-big/ui-big.component';
import { UiQuoteComponent } from './pages/presentation/ui/ui-quote/ui-quote.component';
import { UiNumberComponent } from './pages/presentation/ui/ui-number/ui-number.component';
import { UiVideoComponent } from './pages/presentation/ui/ui-video/ui-video.component';

import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { RunScriptsDirective } from './directives/runScripts.directive';
import { UiWinnerComponent } from './pages/presentation/ui/ui-winner/ui-winner.component';
import { UiPinonimageComponent } from './pages/presentation/ui/ui-pinonimage/ui-pinonimage.component';
import { UiHundredpointsComponent } from './pages/presentation/ui/ui-hundredpoints/ui-hundredpoints.component';
import { UiQuickformComponent } from './pages/presentation/ui/ui-quickform/ui-quickform.component';

import { RealtimeService } from './services/realtime.service';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PresentationHeaderComponent,
    UiMultiplechoiceComponent,
    UiWordcloudComponent,
    UiOpenendedComponent,
    SurveyComponent,
    UiScalesComponent,
    UiHeadingComponent,
    UiParagraphComponent,
    UiBulletsComponent,
    UiImageComponent,
    UiSelectanswerComponent,
    UiTypeanswerComponent,
    UiRankingComponent,
    UiQaComponent,
    UiBigComponent,
    UiQuoteComponent,
    UiNumberComponent,
    UiVideoComponent,
    SafeHtmlPipe,
    RunScriptsDirective,
    UiWinnerComponent,
    UiPinonimageComponent,
    UiHundredpointsComponent,
    UiQuickformComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    TagCloudModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxSliderModule,
    EmbedVideo.forRoot()
  ],
  exports:[RunScriptsDirective],
  providers: [CommonService, PresentationService, RealtimeService],
  bootstrap: [AppComponent]
})
export class AppModule { }
