import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-present-ui-openended',
  templateUrl: './ui-openended.component.html',
  styleUrls: ['./ui-openended.component.scss']
})
export class UiOpenendedComponent implements OnInit {
  public slide: any;

  @Input()
  set slidedata(val: any) {
    this.slide = val;
  }

  public mchoiceForm = new FormGroup({
    textFieldOne: new FormControl(null),
    textField: new FormControl([]),
  });

  constructor(private toastr: ToastrService) {
    console.log(this.mchoiceForm);
  }

  ngOnInit(): void {
  }

  public addMore() {
    if (this.mchoiceForm.get('textFieldOne').value !== '' && this.mchoiceForm.get('textFieldOne').value !== null) {
      let vArr = this.mchoiceForm.get('textField').value;
      vArr.push(this.mchoiceForm.get('textFieldOne').value);
      this.mchoiceForm.get('textField').setValue(vArr);
      this.mchoiceForm.get('textFieldOne').setValue('');
      this.textChanged('dfg');
      this.toastr.success('Success', 'Thanks, we received your input', { positionClass: 'toast-top-center' });
    } else {
      this.toastr.warning('Warning', 'Please enter your input', { positionClass: 'toast-top-center' });
    }
  }
  public textChanged(evt) {
    console.log(this.slide.content.options)
    if(this.slide.content.options){
      this.slide.content.answers = this.mchoiceForm.value.textField;
    } else {
      this.slide.content.answers = this.mchoiceForm.value.textFieldOne;
    }
  }

}
