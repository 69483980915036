<div>
    <div>
      <div [formGroup]="mchoiceForm">
        <div style="text-align: center;">{{slide.content.description}}</div>
        <h2 style="text-align: center;padding: 30px">{{slide.content.question}}</h2>
        <div style="text-align: center;">
          <img *ngIf="this.slide.content.image && this.slide.content.image !== ''" src="{{this.slide.content.image}}"
            class="img-sec">
        </div>
        <div style="margin: 0 auto;width: fit-content;">
          <!-- <div *ngFor="let fld of this.slide.content.options; let i = index">
            <input style="margin-right: 20px;" type="radio" name="textField" formControlName="textField" (change)="textChanged($event)" value="{{slide.content.options[i]}}"> <label style="font-size: 20px;">{{slide.content.options[i]}}
            </label>
          </div> -->
          <div class="custom-control custom-radio" *ngFor="let fld of this.slide.content.options; let i = index">
            <input type="radio" id="{{slide.content.options[i]}}" name="textField"  class="custom-control-input" formControlName="textField" (change)="textChanged($event)" value="{{slide.content.options[i]}}">
            <label class="custom-control-label" for="{{slide.content.options[i]}}" style="font-size: 20px;">{{slide.content.options[i]}}</label>
          </div>
          <!-- <button mat-button mat-raised-button color="primary">Update</button> -->
  
        </div>
      </div>
    </div>
  </div>
  