<div>
  <div [style.text-align]="slide.content.layout === 'background' ? 'center' : ''" [style.height]="slide.content.layout === 'background' ? '95vh' : ''" [style.background]="slide.content.layout === 'background' ? 'url('+this.slide.content.image+')' : ''" [style.background-size]="slide.content.layout === 'background' ? '100% 100%' : ''">
    
      <div [formGroup]="mchoiceForm" style="text-align: center;" >
        <br>
        <img *ngIf="slide.content.layout !== 'background'"  src="{{slide.content.image}}" style="width: 100%;">
        <div style="text-align: center;">{{slide.content.description}}</div>
        <h4 style="text-align: center;">{{slide.content.description}}</h4>
        <h2 style="text-align: center;padding: 30px">{{slide.content.question}}</h2>
        <div style="margin: 0 auto;width: fit-content;" formArrayName="textField" class="d-flex align-items-center mb-1">
          <div *ngFor="let fld of textFieldFormArray.controls; let i = index">
            <label for="box-0" *ngIf="i ==0 && slide.content.options[i]">
              <i class="fas fa-heart {{textFieldFormArray.value[0]?'clr-blue':''}}" style="font-size: 35px;padding: 10px;color: #000000;"></i>
            </label>
            <label for="box-1" *ngIf="i ==1 && slide.content.options[i]">
              <i class="fas fa-question {{textFieldFormArray.value[1]?'clr-blue':''}}" for="box-1" style="font-size: 35px;padding: 10px;color: #000000;"></i>
            </label>
            <label for="box-2" *ngIf="i ==2 && slide.content.options[i]">
              <i class="fas fa-thumbs-up {{textFieldFormArray.value[2]?'clr-blue':''}}" for="box-2" style="font-size: 35px;padding: 10px;color: #000000;"></i>
            </label>
            <label for="box-3" *ngIf="i ==3 && slide.content.options[i]">
              <i class="fas fa-thumbs-down {{textFieldFormArray.value[3]?'clr-blue':''}}" for="box-3" style="font-size: 35px;padding: 10px;color: #000000;"></i>
            </label>
            <input style="margin-right: 20px;display: none;" type="checkbox" id="box-{{i}}" [checked]="textFieldFormArray.value[i]" name="{{i}}" [formControlName]="i"
              (change)="textChanged($event, i)">
          </div>
          <!-- <button mat-button mat-raised-button color="primary">Update</button> -->
        </div>
      </div>
    </div>
  </div>
  