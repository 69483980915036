import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-present-ui-winner',
  templateUrl: './ui-winner.component.html',
  styleUrls: ['./ui-winner.component.scss']
})
export class UiWinnerComponent implements OnInit {
  public slide: any;

  @Input()
  set slidedata(val: any) {
    this.slide = val;
    this.mchoiceForm.get('textField').setValue(this.slide.content.options[0])
  }

  public mchoiceForm = new FormGroup({
    textField: new FormControl('')
  });

  constructor() {

  }

  ngOnInit(): void {
  }

  
  public textChanged(evt) {
    this.slide.content.answers = this.mchoiceForm.value;
  }
}
