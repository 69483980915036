<footer class="footer-container">
    <div class="container">
      <div class="row no-gutters">
        <div class="col-sm-3 wow fadeInUp" data-wow-delay="100ms" data-wow-duration="1s">
          <img src="assets/images/logo.jpg" class="logo-footer mb-4">
          <div class="social-links">
            <h4>Follow us on</h4>
            <a href=""><i class="fab fa-facebook-square"></i></a>
            <a href=""><i class="fab fa-linkedin"></i></a>
            <a href=""><i class="fab fa-twitter"></i></a>
          </div>
        </div>
        <div class="col-sm-9">
          <div class="row wow fadeInUp" data-wow-delay="100ms" data-wow-duration="1s">
            <div class="col-sm-3">
              <h5>Features</h5>
              <ul class="list-unstyled footer-menu">
                <li>
                  <a href="">Overview</a>
                </li>
                <li>
                  <a href="">Word Cloud</a>
                </li>
                <li>
                  <a href="">Quiz</a>
                </li>
                <li>
                  <a href="">Q&amp;A</a>
                </li>
                <li>
                  <a href="">Powerpoint</a>
                </li>
                <li>
                  <a href="">Translations</a>
                </li>
              </ul>
            </div>
            <div class="col-sm-3">
              <h5>Resources</h5>
              <ul class="list-unstyled footer-menu">
                <li>
                  <a href="">Blog</a>
                </li>
                <li>
                  <a href="">Solutions</a>
                </li>
                <li>
                  <a href="">Webinars</a>
                </li>
                <li>
                  <a href="">Campaigns</a>
                </li>
                <li>
                  <a href="">Stories</a>
                </li>
              </ul>
            </div>
            <div class="col-sm-3">
              <h5>Details</h5>
              <ul class="list-unstyled footer-menu">
                <li>
                  <a href="">Terms of use</a>
                </li>
                <li>
                  <a href="">Policies</a>
                </li>
                <li>
                  <a href="">Accessibility</a>
                </li>
                <li>
                  <a href="">Help Center</a>
                </li>
                <li>
                  <a href="">Requirements</a>
                </li>
              </ul>
            </div>
            <div class="col-sm-3">
              <h5>About us</h5>
              <ul class="list-unstyled footer-menu">
                <li>
                  <a href="">Press info</a>
                </li>
                <li>
                  <a href="">The Team</a>
                </li>
                <li>
                  <a href="">Jobs</a>
                </li>
                <li>
                  <a href="">Contact us</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copy-txt text-center">
      Copy@2020 Crerater. All Rights Reserved.
    </div>
  </footer>