import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { io } from "socket.io-client";
@Injectable({
  providedIn: 'root'
})
export class RealtimeService {

  public message$: BehaviorSubject<string> = new BehaviorSubject('');
  public slidechanged$: BehaviorSubject<string> = new BehaviorSubject(undefined);
  public stopped$: BehaviorSubject<string> = new BehaviorSubject(undefined);
  public started$: BehaviorSubject<string> = new BehaviorSubject(undefined);

  constructor() {}

  socket = io('https://crerater.io:3000');
  // socket = io('http://localhost:3000');


  public sendMessage(message) {
    this.socket.emit('message', message);
  }
  public joinSurvey(id) {
    this.socket.emit('join', id);
  }

  public getNewMessage = () => {
    this.socket.on('present', (message) =>{
      this.message$.next(message);
    });
    
    return this.message$.asObservable();
  };

  public slideChanged = () => {
    this.socket.on('slideChanged', (data) =>{
      this.slidechanged$.next(data);
    });
    
    return this.slidechanged$.asObservable();
  };
  public stopped = () => {
    this.socket.on('stopped', (data) =>{
      this.stopped$.next(data);
    });
    
    return this.stopped$.asObservable();
  };

  public started = () => {
    this.socket.on('started', (data) =>{
      this.started$.next(data);
    });
    
    return this.started$.asObservable();
  };

  public slideAnswered(presentation, index, ans) {
    this.socket.emit('answer', {'room': presentation, 'slideindex': index, 'answer': ans});
  }

}
