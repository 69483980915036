import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-present-ui-quickform',
  templateUrl: './ui-quickform.component.html',
  styleUrls: ['./ui-quickform.component.scss']
})
export class UiQuickformComponent implements OnInit {

  
  public slide: any;
  public clicked: any = '';
  constructor() { }
  @Input()
  set slidedata(val: any) {
    this.slide = val;
    this.slide.content.options.forEach(element => {
      this.textFieldFormArray.push(new FormControl(''))
    });
  }

  public mchoiceForm = new FormGroup({
    textField: new FormArray([]),
  });
  ngOnInit(): void {
  }


  get textFieldFormArray() {
    return this.mchoiceForm.controls.textField as FormArray;
  }
  public textChanged(evt, index) {
    
    this.slide.content.answers = this.mchoiceForm.value;

  }

}
