import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    // apiURL: string = 'http://localhost:3000/api';
    // uploadsURL: string = 'http://localhost:3000/uploads';
    // apiURL: string = 'http://208.113.129.65:3000/api';
    // uploadsURL: string = 'http://208.113.129.65:3000/uploads';
    apiURL: string = 'https://crerater.io:3000/api';
    uploadsURL: string = 'https://crerater.io:3000/uploads';

    constructor() { }
    public getSiteUrl() {
        return this.apiURL;
    }
    public getUploadsUrl(){
        return this.uploadsURL;
    }
}