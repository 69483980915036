import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-presentation-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class PresentationHeaderComponent implements OnInit {
  public user: any = {
    name: '',
    email: ''
  };
  constructor(public router: Router) { 
    this.user = {
        name: localStorage.getItem('name'),
        email: localStorage.getItem('email')
    }
  }

  ngOnInit(): void {
  }
  public logout(){
    localStorage.setItem('userLogged', 'false');
    localStorage.setItem('authtoken',  '');
    localStorage.setItem('name', '');
    localStorage.setItem('email', '');
    this.router.navigate(['/']);
  }

}
