
<div>
    <div>
      <div>
        <div style="text-align: center;">{{slide.content.description}}</div>
        <h2 style="text-align: center;padding: 30px">{{slide.content.question}}</h2>
        <div style="text-align: center;">
          <img *ngIf="this.slide.content.image && this.slide.content.image !== ''" src="{{this.slide.content.image}}"
           class="img-sec">
        </div>
        <div class="content-sec" >
          <div *ngFor="let lp of this.loopPurpose; let i = index">
            <b>Rank {{i+1}}</b>
            <br>
            <select style="
            width: 100%;
            padding: 10px;
        " (change)="dropSelect($event.target.value, i)">
              <option class="opt">select</option>
              <option class="opt" *ngFor="let sl of this.dropdownoptions;" [disabled]="sl.disable === 'yes'">
                {{sl.value}}
              </option>
            </select>
          </div>
         
        </div>
      </div>
    </div>
  </div>
  